/* eslint-disable max-len */
import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsPartial,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';

const KeyboardShortcut = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.KeyboardShortcut;
    return <Component {...props} />;
  },
});

import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Histogram"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Modules"
          tierThree="Histogram"
        />
        <PageNavigation
          links={[
            'Behavior',
            'View Modes',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Related',
            'Platform Support',
          ]}
        />
        <Section title="Behavior">
          <SectionSubhead>Selection</SectionSubhead>
          <Paragraph>
            The histogram allows single or multi selection when clicking or
            tapping on individual items. When selected, the entire item,
            including the bar and text, turns blue.
          </Paragraph>
        </Section>

        <Section title="View Modes">
          <Paragraph>
            Histograms can be viewed in the <strong>collapsed</strong>,{' '}
            <strong>expanded</strong>, and <strong>preview</strong>{' '}
            <Link href="../../data-card/design#View%20Modes">
              view modes for data cards
            </Link>
            .
          </Paragraph>
          <SectionSubhead>Collapsed</SectionSubhead>
          <Paragraph>
            When displayed in the <strong>collapsed</strong> view mode, the
            histogram omits labels and percentages and acts as a mini-preview.
            <List>
              <li>
                <strong>Mobile:</strong> When tapping the mini-histogram, the
                view mode should switch to <strong>preview</strong> in order to
                make selection easier for the user. The selected item within the
                histogram should be selected and displayed before switching to{' '}
                <strong>preview</strong> mode.
              </li>
              <li>
                <strong>Web:</strong> When hovering or focusing on a histogram
                item, tooltips are displayed which include the label value of
                that item. On click, the histogram item should be selected.
              </li>
            </List>
          </Paragraph>
          <SectionSubhead>Expanded</SectionSubhead>
          <Paragraph>
            When displayed in the expanded view mode, the histogram list always
            displays all items included in the list.
          </Paragraph>
          <SectionSubhead>Preview</SectionSubhead>
          <Paragraph>
            In the <strong>preview</strong> view mode, the full histogram is
            always displayed.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Module System</SectionSubhead>
          <Paragraph>
            Histograms are part of the module system, and should only be used
            within data cards. Histograms should be used to filter content using
            multiple data set options.
          </Paragraph>
          <DontDo
            dontText="use histograms outside of data cards."
            doText="use histograms within the module system."
            imgFilename="histogram-modules"
          />
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            Label values are required when using histograms. However, in dense
            data sets, overlapping text becomes a larger risk. We recommend
            limiting character length, sometimes omitting or combining labels
            based on the density of the histogram used.
          </Paragraph>
          <SectionSubhead>Web</SectionSubhead>
          <Paragraph>
            <List>
              <li>For 2-4 items, limit labels to 10 characters each.</li>
              <li>For 5-9 items, limit labels to four characters each.</li>
              <li>
                For 10 or more items, limit labels to three characters each.
              </li>
              <li>For more than 12 items, omit at least every other label.</li>
              <li>
                For more than 15 items, omit at least every other label, and
                always show the most positive, most negative, and zero value
                labels.
              </li>
            </List>
          </Paragraph>
          <SectionSubhead>Mobile</SectionSubhead>
          <Paragraph>
            <List>
              <li>For 2-4 items, limit labels to seven characters each.</li>
              <li>For 5-9 items, limit labels to four characters each.</li>
              <li>
                For 10 or more items, limit labels to three characters each.
              </li>
              <li>For more than 12 items, omit at least every other label.</li>
              <li>
                For more than 15 items, omit at least every other label, and
                always show the most positive, most negative, and zero value
                labels.
              </li>
            </List>
          </Paragraph>
          <DontDo
            dontText="use verbose labels in histogram items."
            doText="use concise, logical labels in histogram items."
            imgFilename="histogram-concise"
          />
          <DontDo
            dontText="sacrifice readability to include labels."
            doText=" intentionally hide or combine labels."
            imgFilename="histogram-label"
          />
        </Section>

        <Section title="Accessibility">
          <SectionSubhead>
            Use descriptions when labels aren’t enough
          </SectionSubhead>
          <Paragraph>
            Sometimes the item label alone isn’t enough to aptly convey its
            meaning. For example, a histogram for “Ball Carriers” might have
            labels that include the players’ numbers, but omit the name to save
            space. By adding players’ full names as descriptions, we can provide
            that extra information via{' '}
            <Link
              href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
              isExternal>
              ARIA attributes
            </Link>{' '}
            for screen readers and tooltips.
          </Paragraph>
          <DontDo
            dontText="use tooltips to provide unnecessary information or directions."
            doText="use tooltips to display a concise description when labels could be misunderstood."
            imgFilename="histogram-tooltipcontent"
          />
          <DontDo
            dontText="omit histogram item context in collapsed view mode."
            doText="include relevant item information, like clip count in parenthesis after the label, in a tooltip."
            imgFilename="histogram-minitooltip"
          />
          <SectionSubhead>Keyboard Navigation</SectionSubhead>
          <Paragraph>
            Tab navigation is fully supported by default in histograms. Users
            can press <KeyboardShortcut>enter</KeyboardShortcut> to toggle the
            selection of an item. Sequential tab order will be maintained for
            individual items in both the mini-histogram and larger histograms.
          </Paragraph>
          <SectionSubhead>Clip Count</SectionSubhead>
          <Paragraph>
            When the histogram is collapsed into it’s mini-state, all label and
            clip count information is removed. Use a{' '}
            <Link href="/components/tooltip/design">tooltip</Link> to include
            all of the relevant item information. When selected, include the
            clip count after any additional information.
          </Paragraph>
          <DontDo
            dontText="omit histogram item context in collapsed view mode."
            doText="include any relevant item information to the histogram item in a tooltip."
            imgFilename="histogram-minitooltip"
          />
          <DontDo
            dontText="remove important information."
            doText="include clip count in a tooltip to keep it accessible to the user."
            imgFilename="histogram-clipcount"
          />
          <AccessibilityAuditComponentResultsPartial componentName="Modules / Histogram" />
        </Section>

        <Section title="Related">
          <h3 className="uni-margin--one--bottom">
            <Link href="../../data-card/design">Data Card</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../card-group/design">Card Group</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design#Choosing%20a%20Visualization">
              Data Visualizations
            </Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design">Module System</Link>
          </h3>
          <h3>
            <Link href="../../../../guidelines/accessibility/design">
              Accessibility
            </Link>
          </h3>
        </Section>

        <Section title="Platform Support">
          <Paragraph>
            The histogram is available for React and React Native.
          </Paragraph>
        </Section>
      </PageWithSubNav>
    );
  }
}
